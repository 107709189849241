import Modal from '@elements/Modal';
import InviteProfile from '@forms/InviteProfile';

export default function InviteProfileModal({
  showModal = () => {},
  setShowModal = () => {},
  organizationBundle = {},
  organizationDetails = {},
  setSentInviteData = () => {},
  organizationIdAdmin = '',
  setInviteSent = () => {},
}) {
  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      modalTitle="Voeg profiel toe"
      modalContent={
        <InviteProfile
          setShowModal={setShowModal}
          organizationBundleExpirationDate={organizationBundle?.expiration_date}
          organizationType={organizationDetails?.type || organizationDetails?.organization_type}
          setSentInviteData={setSentInviteData}
          organizationIdAdmin={organizationIdAdmin}
          licenceEndDate={organizationBundle?.expiration_date}
          setInviteSent={setInviteSent}
        />
      }
    />
  );
}
