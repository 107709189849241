import Button from '@elements/buttons/Button';
import InputField from '@elements/forms/InputField';
import PhoneInputField from '@elements/forms/PhoneNumberInput';
import { formatPhoneNumber, getRegionForPhoneNumber } from '@helpers/phoneValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { getOrganizationData, updateOrganization } from '@providers/organization';
import Spinner from 'components/admin/Spinner';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { validationSchema } from './validation';

export default function ContactSettingsModalForm({
  organizationId,
  setShowContactModal,
  organization,
  setOrganization,
  phoneValue,
  setPhoneValue,
  setUpdated,
  setError,
}) {
  const [phoneCountry, setPhoneCountry] = useState('NL');
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  function handlePhoneChange(value) {
    if (value.length > 5) {
      setPhoneValue(value);
      setPhoneCountry(getRegionForPhoneNumber(value));
      setValue('organizationContactNumber', value, { shouldValidate: true });
    }
  }

  async function onSubmit(formData) {
    if (!organization) {
      return;
    }

    const updatedOrganization = {
      contact_email: formData.organizationEmail,
      contact_phone_number: formatPhoneNumber(phoneValue),
      billing_email: formData.billingEmail,
      contract_holder_name: formData.contractHolder,
      contract_holder_email: formData.contractHolderEmail,
    };

    setLoading(true);
    let response = await updateOrganization(organizationId, updatedOrganization);
    if (response && response.status === 200) {
      response = await getOrganizationData(organizationId);
      if (response && response.status === 200) {
        setOrganization(response.data);
      }
      setUpdated(true);
      setShowContactModal(false);
    } else {
      setError(true);
    }
    setLoading(false);
  }

  return (
    <form className="flex flex-col gap-4 pt-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-6">
        <InputField
          id="organizationEmail"
          label="Organisatie e-mail*"
          name="organizationEmail"
          type="text"
          register={register}
          errors={errors}
          message={errors.organizationEmail?.message}
          isValid={!errors.organizationEmail}
          defaultValue={organization?.contact_email || ''}
        />
        <PhoneInputField
          id="organizationContactNumber"
          label="Organisatienummer*"
          name="organizationContactNumber"
          register={register}
          errors={errors}
          message={errors.organizationContactNumber?.message}
          isValid={!errors.organizationContactNumber}
          phoneInputProps={{
            country: phoneCountry,
            value: formatPhoneNumber(phoneValue),
            onChange: handlePhoneChange,
          }}
        />
        <InputField
          id="billingEmail"
          label="Facturatie e-mail*"
          name="billingEmail"
          type="tel"
          register={register}
          errors={errors}
          message={errors.billingEmail?.message}
          isValid={!errors.billingEmail}
          defaultValue={organization?.billing_email || ''}
        />
        <InputField
          id="contractHolder"
          label="Contracthouder*"
          name="contractHolder"
          type="tel"
          register={register}
          errors={errors}
          message={errors.contractHolder?.message}
          isValid={!errors.contractHolder}
          defaultValue={organization?.contract_holder_name || ''}
        />
        <InputField
          id="contractHolderEmail"
          label="Contracthouder e-mail*"
          name="contractHolderEmail"
          type="tel"
          register={register}
          errors={errors}
          message={errors.contractHolderEmail?.message}
          isValid={!errors.contractHolderEmail}
          defaultValue={organization?.contract_holder_email || ''}
        />
      </div>
      <div className="flex justify-end gap-4">
        {loading ? (
          <Spinner spinnerSize="size-10" text="Verzoek aan het verwerken" textSize="text-sm" />
        ) : (
          <>
            <Button
              label="Annuleren"
              type="button"
              buttonType="secondary"
              onClick={() => setShowContactModal(false)}
            />
            <Button label="Versturen" type="submit" buttonType="primary" disabled={!isValid} />
          </>
        )}
      </div>
    </form>
  );
}
