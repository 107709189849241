import { userStore } from '@context/userStore';
import Icon from '@elements/Icon';
import Button from '@elements/buttons/Button';
import formatToDate from '@helpers/formatToDate';
import {
  getOrganizationBundle,
  getOrganizationMembers,
  removeProfilesFromOrganization,
} from '@providers/organization';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import EditLinkedProfileModal from 'components/modals/EditLinkedProfileModal';
import RemoveProfileModal from 'components/modals/RemoveProfileModal';
import { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';
import {
  getEmailColumn,
  getLastLoginColumn,
  getLicenseStatusColumn,
  getLicenseTypesColumn,
  getLinkedProfilesActionsColumn,
  getNameColumn,
  getSelectColumn,
} from './ColumnHelper';

export default function LinkedProfiles() {
  const { organizationId } = userStore();
  const [data, setData] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [showRemoveProfileModal, setShowRemoveProfileModal] = useState(false);
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [profileData, setProfileData] = useState({});
  const [licenceStatus, setLicenceStatus] = useState('');
  const [organizationBundle, setOrganizationBundle] = useState({});
  const [loading, setLoading] = useState(false);
  const [updatedResponse, setUpdatedResponse] = useState(null);
  const [selectedProfileIds, setSelectedProfileIds] = useState([]);
  const [removalLoading, setRemovalLoading] = useState(false);

  useEffect(() => {
    const getMembers = async () => {
      setLoading(true);
      const [orgMemResponse, orgBundleResponse] = await Promise.all([
        getOrganizationMembers(organizationId),
        getOrganizationBundle(organizationId),
      ]);

      if (orgMemResponse && orgMemResponse.status === 204) {
        setData([]);
      }

      if (orgMemResponse && orgMemResponse.status === 200) {
        setData(
          orgMemResponse.data.map((item) => {
            const userBundles = item.linked_bundles || [];
            const activeBundles = userBundles.filter((b) => b.is_active);
            const isAdmin = activeBundles.some(
              (b) => b.bundle_name === 'Enterprise Admin' || b.bundle_name === 'Organization Admin',
            );
            const nonAdminBundle = activeBundles.find(
              (el) =>
                el.bundle_name !== 'Enterprise Admin' && el.bundle_name !== 'Organization Admin',
            );
            const status = nonAdminBundle?.is_active ? 'ACTIVE' : 'NOT_ACTIVE';
            const licenseTypes =
              activeBundles.length > 0
                ? activeBundles.map((b) => b.bundle_name)
                : [orgBundleResponse?.data?.license_type || 'Standard'];

            return {
              profileId: item.profile_id,
              naam: `${item.firstname || ''} ${item.lastname_prefix || ''} ${item.lastname || ''}`.trim(),
              email: item.email,
              laatsteInlogdatum: item.last_login,
              licentieStatus: status === 'ACTIVE' ? 'Actief' : 'Niet actief',
              isBeheerder: isAdmin,
              licenseTypes,
              startDate: nonAdminBundle?.start_date,
              expirationDate: nonAdminBundle?.expiration_date,
            };
          }),
        );
      }

      if (orgBundleResponse && orgBundleResponse.status === 200) {
        setOrganizationBundle(orgBundleResponse.data);
      }

      setLoading(false);
    };
    getMembers();
  }, [organizationId]);

  useEffect(() => {
    if (updatedResponse) {
      setData(
        data.map((item) => {
          if (item.profileId === updatedResponse.profile_id) {
            return {
              ...item,
              licentieStatus: updatedResponse?.is_active ? 'Actief' : 'Niet actief',
              startDate: updatedResponse?.start_date,
              expirationDate: updatedResponse?.expiration_date,
            };
          }
          return item;
        }),
      );
      setUpdatedResponse(null);
    }
  }, [updatedResponse]);

  const columns = useMemo(
    () => [
      getSelectColumn(),
      getNameColumn(),
      getEmailColumn(),
      getLastLoginColumn(),
      getLicenseStatusColumn(),
      getLicenseTypesColumn(),
      getLinkedProfilesActionsColumn(handleEditClick),
    ],
    [organizationBundle, handleEditClick],
  );

  const table = useReactTable({
    data,
    columns,
    state: { sorting, globalFilter: searchInput, rowSelection },
    onSortingChange: setSorting,
    onGlobalFilterChange: setSearchInput,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  async function handleRemoveProfiles() {
    setRemovalLoading(true);
    const payload = {
      profile_ids: selectedProfileIds,
    };

    const response = await removeProfilesFromOrganization(organizationId, payload);

    if (response?.status === 204) {
      toast.success('Gebruikers zijn ontkoppeld.', {
        position: 'top-right',
        icon: <Icon iconName="check" color="text-green-500" />,
        autoClose: 3000,
        closeButton: false,
        bodyClassName: 'font-primary text-green-500',
      });
      setData(data.filter((item) => !selectedProfileIds.includes(item.profileId)));
      setSelectedProfileIds([]);
      setRowSelection({});
      setShowRemoveProfileModal(false);
    } else {
      toast.error('Kan gebruikers niet ontkoppelen.', {
        position: 'top-right',
        autoClose: 3000,
        icon: <Icon iconName="exclamation" color="text-functional-error" />,
        closeButton: false,
        bodyClassName: 'font-primary text-functional-error',
      });
    }
    setRemovalLoading(false);
  }

  function handleRemoveProfileCancel() {
    setSelectedProfileIds([]);
    setShowRemoveProfileModal(false);
  }

  const downloadExcel = useMemo(
    () => () => {
      const headers = [
        ['Naam', 'E-mail', 'Laatste inlogdatum', 'Licentie status', 'Licentie Type'],
      ];

      const licenseTypeMap = {
        'Enterprise Education': 'Educatie',
        'Enterprise Business': 'Business',
        Plus: 'Plus',
        Standaard: 'Standaard',
        'Enterprise Admin': 'Beheerder',
        'Organization Admin': 'Beheerder',
      };

      const rows = data.map((row) => {
        const displayLicenseTypes = row.licenseTypes
          .map((type) => licenseTypeMap[type] || 'Standaard')
          .join(', ');

        return [
          row.naam,
          row.email,
          row.laatsteInlogdatum ? formatToDate(row.laatsteInlogdatum) : '-',
          row.licentieStatus,
          displayLicenseTypes,
        ];
      });

      const worksheet = XLSX.utils.aoa_to_sheet([...headers, ...rows]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Linked Profiles');

      worksheet['!cols'] = [{ wch: 25 }, { wch: 30 }, { wch: 20 }, { wch: 15 }, { wch: 12 }];

      XLSX.writeFile(
        workbook,
        `gekoppelde_profielen_${formatToDate(new Date(), 'DD-MM-YYYY')}.xlsx`,
      );
    },
    [data],
  );

  function handleEditClick(rowData = {}) {
    setProfileData(rowData);
    setLicenceStatus(rowData?.licentieStatus === 'Actief' ? 'ACTIVE' : 'NOT_ACTIVE');
    setShowEditProfileModal(true);
  }

  useEffect(() => {
    if (table.getSelectedRowModel().rows) {
      setSelectedProfileIds(
        table
          .getSelectedRowModel()
          .rows.filter((row) => !row.original.isBeheerder)
          .map((row) => row.original.profileId),
      );
    }
  }, [table.getSelectedRowModel().rows]);

  return (
    <>
      <Header searchInput={searchInput} setSearchInput={setSearchInput} table={table} />
      {loading ? (
        <Skeleton className="h-[400px]" />
      ) : (
        <>
          <ProfileTable table={table} />
          {data.length > 10 && (
            <div className="flex items-center justify-between py-4">
              <div className="flex items-center gap-2">
                <Button
                  type="button"
                  label="Vorige"
                  buttonType="secondary"
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                />
                <Button
                  type="button"
                  label="Volgende"
                  buttonType="secondary"
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                />
                <span className="text-sm text-gray-600">
                  Pagina {table.getState().pagination.pageIndex + 1} van {table.getPageCount()}
                </span>
              </div>
            </div>
          )}
          <div className="flex w-full justify-end gap-3">
            <Button
              type="button"
              label="Excel downloaden"
              overrideClass="text-red-500"
              buttonType="primary"
              hoverText="Download tabel als Excel"
              onClick={downloadExcel}
            />
            <Button
              type="button"
              label={`${selectedProfileIds.length > 1 ? 'Profielen' : 'Profiel'} verwijderen`}
              overrideClass="text-white/90"
              buttonType="danger"
              disabled={selectedProfileIds.length === 0}
              onClick={() => setShowRemoveProfileModal(true)}
            />
          </div>
        </>
      )}
      <RemoveProfileModal
        showModal={showRemoveProfileModal}
        setShowModal={setShowRemoveProfileModal}
        onCancel={handleRemoveProfileCancel}
        onConfirm={handleRemoveProfiles}
        removalLoading={removalLoading}
        selectedProfileIds={selectedProfileIds}
      />
      <EditLinkedProfileModal
        showModal={showEditProfileModal}
        setShowModal={setShowEditProfileModal}
        profileData={profileData}
        licenceStatus={licenceStatus}
        organizationBundle={organizationBundle}
        organizationId={organizationId}
        setLicenceStatus={setLicenceStatus}
        onModalClose={() => setShowEditProfileModal(false)}
        setUpdatedResponse={setUpdatedResponse}
      />
    </>
  );
}

const Header = ({ searchInput, setSearchInput, table }) => (
  <div className="flex justify-between">
    <div className="flex flex-col">
      <h2 className="font-primary text-3xl">Gekoppelde profielen</h2>
      <p className="mb-8 mt-1 font-secondary">
        Bekijk de status van profielen gekoppeld aan de Organisatie.
      </p>
    </div>
    <div>
      <input
        type="text"
        placeholder="Zoeken"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        className="mb-2 h-10 w-64 rounded-md border px-4 py-2 text-sm"
      />
      <div className="mb-2 pl-1 text-sm text-gray-600">
        {table.getFilteredRowModel().rows.length} Resultaten
      </div>
    </div>
  </div>
);

const ProfileTable = ({ table }) => (
  <div className="mb-12 flex min-w-full flex-col overflow-x-auto rounded-lg shadow-lg">
    <table className="min-w-full border-collapse rounded-lg border border-gray-300">
      <thead className="bg-primary-light text-left">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className="border-b border-gray-300 px-6 py-4 text-sm font-bold text-gray-700"
              >
                <div className="flex items-center gap-2">
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row, rowIndex) => (
          <tr
            key={row.id}
            className={`${rowIndex % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100`}
          >
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                className="border-b border-gray-300 px-6 py-4 text-sm text-gray-600"
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);
