import { userStore } from '@context/userStore';
import Icon from '@elements/Icon';
import Button from '@elements/buttons/Button';
import InputField from '@elements/forms/InputField';
import { handleValidation } from '@helpers/handleValidation';
import stripTimezoneOffset from '@helpers/stripTimezoneOffset';
import { yupResolver } from '@hookform/resolvers/yup';
import { getOrganizationInvites, inviteToOrganization } from '@providers/organization';
import Spinner from 'components/admin/Spinner';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';

export default function InviteProfile({
  setShowModal,
  orgBundleExpirationDate,
  organizationIdAdmin,
  organizationType,
  setSentInviteData,
  licenceEndDate,
  setInviteSent,
}) {
  const { organizationId } = userStore();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(licenceEndDate ? new Date(licenceEndDate) : null);
  const [invitationData, setInvitationData] = useState([]);
  const [isValid, setIsValid] = useState({});
  const [loading, setLoading] = useState(false);
  const [expirationExceeded, setExpirationExceeded] = useState(false);
  const [emailInvitedMessage, setEmailInvitedMessage] = useState('');

  const orgId = organizationIdAdmin || organizationId;
  const isPlatformAdmin = organizationIdAdmin ? true : false;

  function handleDateChange(type, e) {
    const { value } = e.target;
    if (!value) {
      if (type === 'start') setStartDate(new Date());
      if (type === 'end') setEndDate(null);
      return;
    }
    const date = new Date(value);
    if (isNaN(date.getTime())) return;

    if (type === 'start') {
      setStartDate(date);
      if (endDate && endDate < date) setEndDate(null);
    }
    if (type === 'end') {
      if (orgBundleExpirationDate && date > new Date(orgBundleExpirationDate)) {
        setExpirationExceeded(true);
      } else {
        setExpirationExceeded(false);
      }
      setEndDate(date);
    }
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Ongeldig emailformaat')
      .test('is-invited', 'Gebruiker is al uitgenodigd', (val) => {
        const invitationEmails = invitationData
          .filter((invite) => invite.invite_status !== 'REVOKED')
          .map((invite) => invite.email);

        return !invitationEmails.includes(val);
      })
      .required('Email is verplicht'),
  });

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  function handleValidationWrapper(e, error) {
    if (error?.type === 'is-invited' && error?.message) {
      setEmailInvitedMessage(error?.message);
    } else {
      setEmailInvitedMessage('');
    }
    handleValidation(e, error, trigger, setIsValid);
  }

  const onSubmit = async (data) => {
    setLoading(true);
    const payload = {
      organization_id: orgId,
      email: data.email.toLowerCase(),
      license_start_date: stripTimezoneOffset(startDate),
      license_end_date: endDate ? stripTimezoneOffset(endDate) : null,
      invite_type: organizationType === 'ENTERPRISE' ? 'ENTERPRISE_USER' : 'ORGANIZATION_USER',
    };

    const response = await inviteToOrganization(payload);

    if (response?.status === 201) {
      toast.success('Uitnodiging verzonden', {
        position: 'top-right',
        icon: <Icon iconName="check" color="text-green-500" />,
        autoClose: 3000,
        closeButton: false,
        bodyClassName: 'font-primary text-green-500',
      });
      setSentInviteData(response.data);
      setInviteSent(true);
      setShowModal(false);
    } else if (response?.status === 204) {
      toast.error('Uitnodiging niet verzonden, geen licentieplaatsen vrij', {
        position: 'top-right',
        icon: <Icon iconName="exclamation" color="text-functional-error" />,
        autoClose: 3000,
        closeButton: false,
        bodyClassName: 'font-primary text-functional-error',
      });
    } else if (response?.status === 409) {
      toast.error('Uitnodiging niet verzonden, dit e-mailadres is al uitgenodigd', {
        position: 'top-right',
        autoClose: 3000,
        icon: <Icon iconName="exclamation" color="text-functional-error" />,
        closeButton: false,
        bodyClassName: 'font-primary text-functional-error',
      });
    } else {
      toast.error('Kon de uitnodiging niet versturen', {
        position: 'top-right',
        autoClose: 3000,
        icon: <Icon iconName="exclamation" color="text-functional-error" />,
        closeButton: false,
        bodyClassName: 'font-primary text-functional-error',
      });
    }
    setLoading(false);
  };

  const isValidEmail = 'email' in isValid && isValid['email'] ? true : false;
  const formIsValid = !expirationExceeded && isValidEmail ? true : false;

  useEffect(() => {
    async function getOrgInvites() {
      const response = await getOrganizationInvites(orgId, isPlatformAdmin);
      if (response?.status === 204) {
        setInvitationData([]);
      }
      if (response?.status === 200) {
        setInvitationData(response.data);
      }
    }
    getOrgInvites();
  }, [orgId]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-4">
        {emailInvitedMessage && (
          <div className="font-primary text-sm font-light tracking-wide text-functional-error">
            {emailInvitedMessage}
          </div>
        )}
        <InputField
          label="Email*"
          name="email"
          type="text"
          placeholder="jan@bouwbedrijf.nl"
          register={register}
          onKeyUp={(e) => handleValidationWrapper(e, errors.email)}
          message={errors.email?.message}
          isValid={isValid.email}
        />
        <InputField
          id="startDate"
          label="Selecteer begin datum*"
          name="startDate"
          type="date"
          onChange={(e) => handleDateChange('start', e)}
          onKeyDown={(e) => e.preventDefault()}
          min={new Date(startDate).toISOString().split('T')[0]}
          defaultValue={new Date(startDate).toISOString().split('T')[0]}
        />
        <InputField
          id="endDate"
          label="Selecteer einddatum"
          name="endDate"
          type="date"
          onChange={(e) => handleDateChange('end', e)}
          onKeyDown={(e) => e.preventDefault()}
          min={new Date(startDate).toISOString().split('T')[0]}
          max={
            orgBundleExpirationDate
              ? new Date(orgBundleExpirationDate).toISOString().split('T')[0]
              : null
          }
          defaultValue={endDate ? endDate.toISOString().split('T')[0] : null}
          customError={expirationExceeded}
          message={expirationExceeded ? 'Licentie einddatum overschreden' : null}
        />
        <div className="flex justify-end gap-4">
          {loading ? (
            <Spinner spinnerSize="size-10" text="Verzoek aan het verwerken" textSize="text-sm" />
          ) : (
            <>
              <Button
                label="Annuleren"
                type="button"
                buttonType="secondary"
                onClick={() => setShowModal(false)}
              />
              <Button
                label="Uitnodigen"
                type="submit"
                buttonType="primary"
                disabled={!formIsValid}
              />
            </>
          )}
        </div>
      </div>
    </form>
  );
}
