import { industries } from '@config/industries';
import { userStore } from '@context/userStore';
import DetailList from '@elements/DetailList';
import Icon from '@elements/Icon';
import Modal from '@elements/Modal';
import Button from '@elements/buttons/Button';
import AdminSettingsModalForm from '@forms/AdminSettingsModalForm';
import ContactSettingsModalForm from '@forms/ContactSettingsModalForm';
import OrgnanizationBusinessSettingsModalForm from '@forms/OrganizationBusinessSettingsForm';
import { getOrganizationData } from '@providers/organization';
import { getProfileData } from '@providers/profile';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';

const phoneUtil = PhoneNumberUtil.getInstance();

export default function OrganizationBusinessDetailsForm() {
  const { organizationId } = userStore();
  const { profileId } = userStore();
  const [profile, setProfileData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [organization, setOrganization] = useState(null);
  const [organizationIndustry, setOrganizationIndustry] = useState(industries.choices[0].value);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [profilePhoneValue, setProfilePhoneValue] = useState();
  const [organizationPhoneValue, setOrganizationPhoneValue] = useState();
  const [updated, setUpdated] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onClickModal = (modal) => {
    if (modal === 'business') {
      setShowModal(!showModal);
    } else if (modal === 'contact') {
      setShowContactModal(!showContactModal);
    } else if (modal === 'admin') {
      setShowAdminModal(!showAdminModal);
    }
  };

  const getOrganizationInformation = async () => {
    setIsLoading(true);
    const [organizationResponse, profileResponse] = await Promise.all([
      getOrganizationData(organizationId),
      getProfileData(profileId),
    ]);
    if (organizationResponse && organizationResponse.status === 200) {
      setOrganization(organizationResponse.data);
      const matchingIndustry = industries.choices.find(
        (choice) => choice.value === organizationResponse.data.branch_name,
      );
      setOrganizationIndustry(matchingIndustry?.value || industries.choices[0].value);
      setOrganizationPhoneValue(organizationResponse.data.contact_phone_number);
    }
    if (profileResponse && profileResponse.status === 200) {
      setProfileData(profileResponse.data);
      setProfilePhoneValue(profileResponse.data.contact_phone_number);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getOrganizationInformation(organizationId);
  }, [organizationId]);

  let formattedOrganizationNumber;
  let formattedProfileContactNumber;

  function formatToNational(phoneNum, countryCode) {
    const parsedNumber = phoneUtil.parse(phoneNum, countryCode);
    return phoneUtil.format(parsedNumber, PhoneNumberFormat.NATIONAL);
  }

  if (organization && organization.contact_phone_number) {
    const databaseNumberValue = organization.contact_phone_number;
    formattedOrganizationNumber = formatToNational(databaseNumberValue, 'NL');
  } else {
    formattedOrganizationNumber = organizationPhoneValue;
  }

  if (profile && profile.phone_number) {
    formattedProfileContactNumber = formatToNational(profile.phone_number, 'NL');
  } else {
    formattedProfileContactNumber = profilePhoneValue;
  }

  const userCompanyDetails = [
    { label: 'Bedrijfsnaam', value: organization?.organization_name },
    { label: 'Branche', value: organization?.branch_name },
    { label: 'Vestigingslocatie', value: organization?.branch_location },
    { label: 'KvK-nummer', value: organization?.chamber_of_commerce_number },
    { label: 'Vestigingsnummer', value: organization?.branch_number },
    { label: 'BTW-nummer', value: organization?.vat_number },
  ];

  const contactDetails = [
    { label: 'Organisatie e-mail', value: organization?.contact_email },
    { label: 'Organisatie nummer', value: formattedOrganizationNumber },
    { label: 'Facturatie e-mail', value: organization?.billing_email },
    { label: 'Contracthouder', value: organization?.contract_holder_name },
    { label: 'Contracthouder e-mail', value: organization?.contract_holder_email },
  ];

  const adminDetails = [
    { label: 'Beheerder', value: organization?.admin_name },
    { label: 'Beheerder nummer', value: formattedProfileContactNumber },
    { label: 'Beheerder e-mail', value: profile?.email },
  ];

  const organizationModalFormProps = {
    industries,
    organizationId,
    setShowModal,
    organization,
    setOrganization,
    setIndustry: setOrganizationIndustry,
    industry: organizationIndustry,
    setUpdated,
    setError,
  };

  const contactModalFormProps = {
    organizationId,
    setShowContactModal,
    organization,
    setOrganization,
    phoneValue: organizationPhoneValue,
    setPhoneValue: setOrganizationPhoneValue,
    setUpdated,
    setError,
  };

  const adminModalFormProps = {
    organizationId,
    setShowAdminModal,
    organization,
    setOrganization,
    phoneValue: profilePhoneValue,
    setPhoneValue: setProfilePhoneValue,
  };

  useEffect(() => {
    if (updated) {
      toast.success('Gegevens bijgewerkt', {
        position: 'top-right',
        icon: <Icon iconName="check" color="text-green-500" />,
        autoClose: 3000,
        closeButton: false,
        bodyClassName: 'font-primary text-green-500',
      });
      setUpdated(false);
    }
    if (error) {
      toast.error('Kan de gegevens niet bijwerken', {
        position: 'top-right',
        autoClose: 3000,
        icon: <Icon iconName="exclamation" color="text-functional-error" />,
        closeButton: false,
        bodyClassName: 'font-primary text-functional-error',
      });
      setError(false);
    }
  }, [updated, error]);

  return (
    <div className="container mx-auto pb-10 font-primary">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h2 className="font-primary text-3xl">Bedrijfsgegevens</h2>
          <p className="mb-8 mt-1">Bekijk hier je bedrijfsgegevens.</p>
        </div>

        <div className="mt-1">
          <Button
            type="button"
            icon="pencil"
            buttonType="secondary"
            onClick={() => onClickModal('business')}
          />
        </div>
      </div>

      {!isLoading ? (
        <DetailList details={userCompanyDetails} />
      ) : (
        <div>
          {[...Array(1)].map((_, index) => (
            <div key={index}>
              <Skeleton className="h-[400px]" />
            </div>
          ))}
        </div>
      )}
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        modalTitle="Werk mijn bedrijfsgegevens bij"
        modalContent={<OrgnanizationBusinessSettingsModalForm {...organizationModalFormProps} />}
      />

      <div className="flex justify-between pt-10 ">
        <div className="flex flex-col">
          <h2 className="font-primary text-3xl">Contactgegevens</h2>
          <p className="mb-8 mt-1">Bekijk hier je contactgegevens.</p>
        </div>
        <div className="mt-1">
          <Button
            type="button"
            icon="pencil"
            buttonType="secondary"
            onClick={() => onClickModal('contact')}
          />
        </div>
      </div>
      <Modal
        showModal={showContactModal}
        setShowModal={setShowContactModal}
        modalTitle="Werk mijn contactgegevens bij"
        modalContent={<ContactSettingsModalForm {...contactModalFormProps} />}
      />
      {!isLoading ? (
        <DetailList details={contactDetails} />
      ) : (
        <div>
          {[...Array(1)].map((_, index) => (
            <div key={index}>
              <Skeleton className="h-[400px]" />
            </div>
          ))}
        </div>
      )}

      <div className="flex justify-between pt-10 ">
        <div className="flex flex-col">
          <h2 className="font-primary text-3xl">Beheerdergegevens</h2>
          <p className="mb-8 mt-1">Bekijk hier je beheerdergegevens.</p>
        </div>
      </div>
      <Modal
        showModal={showAdminModal}
        setShowModal={setShowAdminModal}
        modalTitle="Werk mijn beheerdergegevens bij"
        modalContent={<AdminSettingsModalForm {...adminModalFormProps} />}
      />
      {!isLoading ? (
        <DetailList details={adminDetails} />
      ) : (
        <div>
          {[...Array(1)].map((_, index) => (
            <div key={index}>
              <Skeleton className="h-[400px]" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
