import { logoutProfile } from '@providers/profile';

export async function logout(setUserStore) {
  await logoutProfile();
  setUserStore(
    false,
    '',
    '',
    [],
    [],
    [],
    false,
    false,
    false,
    '',
    false,
    '',
    '',
    false,
    '',
    '',
    '',
    '',
  );

  return true;
}
