import Modal from '@elements/Modal';
import Button from '@elements/buttons/Button';
import Spinner from 'components/admin/Spinner';

export default function RevokeInviteModal({
  showModal = () => {},
  setShowModal = () => {},
  onCancel = () => {},
  onConfirm = () => {},
  revokeLoading = false,
  selectedInviteEmails = [],
}) {
  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      modalTitle="Profiel ontkoppelen van organisatie"
      modalContent={
        <div>
          <p className="mb-4 mt-2 font-secondary">
            Weet je zeker dat je de
            {selectedInviteEmails.length > 1 ? ' uitnodigingen' : ' uitnodiging'} wilt intrekken?
          </p>
          <div className="flex justify-end gap-3 pt-2">
            {revokeLoading ? (
              <Spinner spinnerSize="size-10" text="Verzoek aan het verwerken" textSize="text-md" />
            ) : (
              <>
                <Button type="button" buttonType="secondary" onClick={onCancel} label="Annuleren" />
                <Button
                  type="button"
                  overrideClass="text-white/90"
                  buttonType="danger"
                  onClick={onConfirm}
                  label={`${selectedInviteEmails.length > 1 ? 'Uitnodigingen' : 'Uitnodiging'} intrekken`}
                />
              </>
            )}
          </div>
        </div>
      }
    />
  );
}
