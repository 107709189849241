import Modal from '@elements/Modal';
import EditLinkedProfile from '@forms/EditLinkedProfile';

export default function EditLinkedProfileModal({
  showModal = () => {},
  setShowModal = () => {},
  profileData = {},
  licenceStatus = '',
  organizationBundle = {},
  organizationId = '',
  setLicenceStatus = () => {},
  onModalClose = () => {},
  setUpdatedResponse = null,
}) {
  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      modalTitle="Wijzig toegang tot de licentie voor:"
      modalContent={
        <div>
          <EditLinkedProfile
            email={profileData?.email}
            licenceStatus={licenceStatus}
            licenceStartDate={profileData?.startDate}
            licenceEndDate={profileData?.expirationDate}
            setShowModal={setShowModal}
            bundleId={organizationBundle?.bundle_id}
            profileId={profileData?.profileId}
            organizationId={organizationId}
            organizationBundleExpirationDate={organizationBundle?.expiration_date}
            setLicenceStatus={setLicenceStatus}
            onModalClose={onModalClose}
            setUpdatedResponse={setUpdatedResponse}
          />
        </div>
      }
    />
  );
}
