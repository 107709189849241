import Modal from '@elements/Modal';
import Button from '@elements/buttons/Button';
import Spinner from 'components/admin/Spinner';

export default function RemoveProfileModal({
  showModal = () => {},
  setShowModal = () => {},
  onCancel = () => {},
  onConfirm = () => {},
  removalLoading = false,
  selectedProfileIds = [],
}) {
  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      modalTitle="Profiel ontkoppelen van organisatie"
      modalContent={
        <div>
          <p className="mb-4 font-secondary text-sm">
            Weet je zeker dat je de selectie wilt verwijderen?
            <br /> Hierdoor hebben ze geen toegang meer tot de licentie.
            <br /> Het persoonlijke BouwZo profiel blijft wel bestaan.
          </p>
          <div className="flex justify-end gap-3 pt-2">
            {removalLoading ? (
              <Spinner spinnerSize="size-10" text="Verzoek aan het verwerken" textSize="text-md" />
            ) : (
              <>
                <Button type="button" buttonType="secondary" onClick={onCancel} label="Annuleren" />
                <Button
                  type="button"
                  buttonType="danger"
                  onClick={onConfirm}
                  label={`${selectedProfileIds.length > 1 ? 'Profielen' : 'Profiel'} verwijderen`}
                />
              </>
            )}
          </div>
        </div>
      }
    />
  );
}
