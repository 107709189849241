import { userStore } from '@context/userStore';
import Icon from '@elements/Icon';
import Button from '@elements/buttons/Button';
import { isOrganisationAdmin, sendEnterpriseExtendSeatsRequest } from '@providers/enterprise';
import {
  getInviteTabelSeatCount,
  getOrganizationBundle,
  getOrganizationData,
  getOrganizationInvites,
  resendInvite,
  revokeInvite,
} from '@providers/organization';
import { getProfileData } from '@providers/profile';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import InviteProfileModal from 'components/modals/InviteProfileModal';
import ResendInviteModal from 'components/modals/ResendInviteModal';
import RevokeInviteModal from 'components/modals/RevokeInviteModal';
import { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getEmailColumn,
  getInvitationDateColumn,
  getInviteActionsColumn,
  getInviteSelectColumn,
  getInviteStatusColumn,
  getLicenseEndDateColumn,
  getLicenseStartDateColumn,
} from './ColumnHelper';

export default function InviteTable() {
  const { organizationId, profileId } = userStore();
  const [invitationData, setInvitationData] = useState([]);
  const [showInviteProfileModal, setShowInviteProfileModal] = useState(false);
  const [organizationBundle, setOrganizationBundle] = useState({});
  const [showResendInviteModal, setShowResendInviteModal] = useState(false);
  const [resendInviteData, setResendInviteData] = useState({});
  const [organizationDetails, setOrganizationDetails] = useState(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sentInviteData, setSentInviteData] = useState(null);
  const [resendLoading, setResendLoading] = useState(false);
  const [selectedInviteEmails, setSelectedInviteEmails] = useState([]);
  const [revokeLoading, setRevokeLoading] = useState(false);
  const [showRevokeInviteModal, setShowRevokeInviteModal] = useState(false);
  const [seatsAvailable, setSeatsAvailable] = useState(true);
  const [seatsLeft, setSeatsLeft] = useState(null);
  const [inviteSent, setInviteSent] = useState(false);
  const [revoked, setRevoked] = useState(false);
  const [showSeatWarningModal, setShowSeatWarningModal] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailNotSent, setEmailNotSent] = useState(false);

  function handleShowResendInviteModal(rowData = {}) {
    setResendInviteData(rowData);
    setShowResendInviteModal(true);
  }

  async function handleResendInvite() {
    setResendLoading(true);
    const payload = {
      organization_id: organizationId,
      email: resendInviteData?.email,
      invite_type:
        organizationDetails?.type === 'ENTERPRISE' ? 'ENTERPRISE_USER' : 'ORGANIZATION_USER',
    };

    const response = await resendInvite(payload);

    if (response && response.status === 200) {
      toast.success('De uitnodiging is opnieuw verzonden', {
        position: 'top-right',
        icon: <Icon iconName="check" color="text-green-500" />,
        autoClose: 3000,
        closeButton: false,
        bodyClassName: 'font-primary text-green-500',
      });
      setInvitationData(
        invitationData.map((item) => {
          if (item.email === response.data.email) {
            return {
              ...item,
              email: response.data.email,
              inviteStatus: response.data.status,
              inviteType: response.data.invite_type,
              licenceStartDate: response.data.license_start_date,
              licenceEndDate: response.data.license_end_date,
              invitationDate: response.data.created_on,
              organizationId: response.data.organization_id,
              profileExists: response.data.account_exists,
            };
          } else {
            return item;
          }
        }),
      );
      setShowResendInviteModal(false);
    } else {
      toast.error('Kon de uitnodiging niet opnieuw verzenden', {
        position: 'top-right',
        autoClose: 3000,
        icon: <Icon iconName="exclamation" color="text-functional-error" />,
        closeButton: false,
        bodyClassName: 'font-primary text-functional-error',
      });
    }
    setResendLoading(false);
  }

  async function handleRevokeInvites() {
    setRevokeLoading(true);
    const payload = {
      emails: selectedInviteEmails,
      organization_id: organizationId,
    };

    const response = await revokeInvite(payload);

    if (response && response.status === 204) {
      toast.success('Uitnodigingen zijn ingetrokken', {
        position: 'top-right',
        icon: <Icon iconName="check" color="text-green-500" />,
        autoClose: 3000,
        closeButton: false,
        bodyClassName: 'font-primary text-green-500',
      });
      setInvitationData(
        invitationData.filter((item) => !selectedInviteEmails.includes(item.email)),
      );
      table.resetRowSelection();
      setSelectedInviteEmails([]);
      setRevoked(true);
      setShowRevokeInviteModal(false);
    } else {
      toast.error('Kon uitnodigingen niet intrekken', {
        position: 'top-right',
        autoClose: 3000,
        icon: <Icon iconName="exclamation" color="text-functional-error" />,
        closeButton: false,
        bodyClassName: 'font-primary text-functional-error',
      });
    }
    setRevokeLoading(false);
  }

  async function handleUpgradeRequest() {
    const [profileDataResponse, isOrganisationAdminResponse] = await Promise.all([
      getProfileData(),
      isOrganisationAdmin(profileId),
    ]);

    if (profileDataResponse?.status === 200 && isOrganisationAdminResponse?.status === 200) {
      const payload = {
        organization_name: isOrganisationAdminResponse.data.organization_name,
        email: profileDataResponse.data.email,
        first_name: profileDataResponse.data.firstname,
        last_name_prefix: profileDataResponse.data.lastname_prefix,
        last_name: profileDataResponse.data.lastname,
        phone_number: profileDataResponse.data.phone_number,
      };

      const extendSeatsResponse = await sendEnterpriseExtendSeatsRequest(payload);

      if (extendSeatsResponse?.status === 204) {
        setEmailSent(true);
        setShowSeatWarningModal(false);
      } else {
        setEmailNotSent(true);
      }
    } else {
      setEmailNotSent(true);
    }
  }

  useEffect(() => {
    if (emailSent) {
      toast.success('Email verzonden.', {
        position: 'top-right',
        icon: <Icon iconName="check" color="text-green-500" />,
        autoClose: 3000,
        closeButton: false,
        bodyClassName: 'font-primary text-green-500',
      });
      setEmailSent(false);
    }
    if (emailNotSent) {
      toast.error('Email niet verzonden.', {
        position: 'top-right',
        autoClose: 3000,
        icon: <Icon iconName="exclamation" color="text-functional-error" />,
        closeButton: false,
        bodyClassName: 'font-primary text-functional-error',
      });
      setEmailNotSent(false);
    }
  }, [emailSent, emailNotSent]);

  useEffect(() => {
    async function getInvitations() {
      setLoading(true);
      const [orgResponse, orgInviteResponse, orgBundleResponse] = await Promise.all([
        getOrganizationData(organizationId),
        getOrganizationInvites(organizationId, false),
        getOrganizationBundle(organizationId),
      ]);
      if (orgResponse && orgResponse.status === 200) {
        setOrganizationDetails(orgResponse.data);
      }
      if (orgInviteResponse && orgInviteResponse.status === 204) {
        setInvitationData([]);
      }
      if (orgInviteResponse && orgInviteResponse.status === 200) {
        setInvitationData(
          orgInviteResponse.data.map((item) => ({
            email: item.email,
            inviteStatus: item.invite_status,
            inviteType: item.invite_type,
            licenceStartDate: item.start_date,
            licenceEndDate: item.expiration_date,
            invitationDate: item.invitation_date,
            organizationId: item.organization_id,
            profileExists: item.profile_exists,
          })),
        );
      }
      if (orgBundleResponse && orgBundleResponse.status === 200) {
        setOrganizationBundle(orgBundleResponse.data);
      }
      setLoading(false);
    }
    getInvitations();
  }, [organizationId]);

  useEffect(() => {
    async function getSeatCount() {
      const response = await getInviteTabelSeatCount(organizationId);
      if (response && response.status === 200) {
        setSeatsAvailable(response.data.available);
        setSeatsLeft(response.data.seats_left);
        setShowSeatWarningModal(response.data.seats_left === 0);
      }
    }
    getSeatCount();
  }, [organizationId, inviteSent, revoked, sentInviteData, invitationData]);

  useEffect(() => {
    if (sentInviteData) {
      setInvitationData([
        {
          email: sentInviteData?.email,
          inviteStatus: sentInviteData?.status,
          inviteType: sentInviteData?.invite_type,
          licenceStartDate: sentInviteData?.license_start_date,
          licenceEndDate: sentInviteData?.license_end_date,
          invitationDate: sentInviteData?.created_on,
          organizationId: sentInviteData?.organization_id,
          profileExists: sentInviteData?.account_exists,
        },
        ...invitationData,
      ]);
    }
  }, [sentInviteData]);

  const columns = useMemo(
    () => [
      getInviteSelectColumn(),
      getEmailColumn(),
      getInvitationDateColumn(),
      getInviteStatusColumn(),
      getLicenseStartDateColumn(),
      getLicenseEndDateColumn(),
      getInviteActionsColumn(handleShowResendInviteModal),
    ],
    [handleShowResendInviteModal], // Updated dependencies
  );

  const filteredData = useMemo(() => {
    return globalFilter
      ? invitationData.filter((item) =>
          item.email?.toLowerCase().includes(globalFilter.toLowerCase()),
        )
      : invitationData;
  }, [invitationData, globalFilter]);

  const table = useReactTable({
    data: filteredData,
    columns,
    state: { sorting, globalFilter },
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  useEffect(() => {
    if (table.getSelectedRowModel().rows) {
      setSelectedInviteEmails(table.getSelectedRowModel().rows.map((row) => row.original.email));
    }
  }, [table.getSelectedRowModel().rows]);

  function handleCancelRevokeInvite() {
    table.resetRowSelection();
    setSelectedInviteEmails([]);
    setShowRevokeInviteModal(false);
  }

  function SeatWarningModal({ showModal, setShowModal }) {
    return (
      <div
        className={`fixed inset-0 z-50 flex items-center justify-center bg-black/50 ${
          showModal ? 'block' : 'hidden'
        }`}
      >
        <div className="w-[650px] rounded-lg bg-white p-6 shadow-lg">
          <h2 className="mb-4 text-xl font-bold">Maximum licentieplaatsen bereikt</h2>
          <p>Je gebruikt het maximum aantal licentieplaatsen. Wil je meer profielen toevoegen?</p>
          <p className="mb-4">
            Stuur een verzoek naar de klantenservice om je licentie uit te breiden.
          </p>
          <div className="flex justify-end gap-4">
            <Button label="Annuleren" onClick={() => setShowModal(false)} buttonType="secondary" />
            <Button label="Plan Upgraden" onClick={handleUpgradeRequest} buttonType="primary" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h2 className="font-primary text-3xl">Uitgenodigde profielen</h2>
          <p className="mt-1 font-secondary">Bekijk de status van alle uitnodigingen.</p>
          {loading ? (
            <Skeleton width={150} height={16} className="mb-8" />
          ) : (
            <p className="mb-8 text-sm text-gray-600">
              Licentieplaatsen beschikbaar: {seatsLeft && seatsLeft}
            </p>
          )}
        </div>
        <div>
          <input
            type="text"
            placeholder="Zoeken"
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            className="mb-2 h-10 w-64 rounded-md border px-4 py-2 text-sm"
          />
          <div className="mb-2 pl-1 text-sm text-gray-600">
            {table.getFilteredRowModel().rows.length} Resultaten
          </div>
        </div>
      </div>
      {loading ? (
        <Skeleton className="h-[400px]" />
      ) : (
        <>
          <div className="mb-12 flex min-w-full flex-col rounded-lg bg-white shadow-lg">
            <table className="min-w-full border-collapse border border-gray-300">
              <thead className="rounded-t-lg bg-primary-light">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        className="border-b border-gray-300 py-4 pl-6 text-sm font-bold text-gray-700"
                      >
                        <div className="flex items-center gap-2">
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row, rowIndex) => (
                  <tr
                    key={row.id}
                    className={`${rowIndex % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100`}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className="border-b border-gray-300 px-6 py-4 text-sm text-gray-600"
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {filteredData.length > 10 && (
            <div className="flex items-center justify-between py-4">
              <div className="flex items-center gap-2">
                <Button
                  type="button"
                  label="Vorige"
                  buttonType="secondary"
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                />
                <Button
                  type="button"
                  label="Volgende"
                  buttonType="secondary"
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                />
                <span className="text-sm text-gray-600">
                  Pagina {table.getState().pagination.pageIndex + 1} van {table.getPageCount()}
                </span>
              </div>
            </div>
          )}
          <div className="flex items-center justify-end gap-4">
            <Button
              label={seatsAvailable ? 'Nodig profiel uit' : 'Geen licentieplaatsen beschikbaar'}
              onClick={() => setShowInviteProfileModal(true)}
              disabled={!seatsAvailable}
              hoverText={
                !seatsAvailable ? 'Er zijn geen plaatsen beschikbaar. Upgrade de licentie' : ''
              }
            />
            <Button
              type="button"
              label={`${selectedInviteEmails.length > 1 ? 'Uitnodigingen' : 'Uitnodiging'} intrekken`}
              overrideClass="text-white/90"
              buttonType="danger"
              hoverText="Trek een uitnodiging voor je organisatie in"
              disabled={selectedInviteEmails.length === 0}
              onClick={() => setShowRevokeInviteModal(true)}
            />
          </div>
        </>
      )}
      <InviteProfileModal
        showModal={showInviteProfileModal}
        setShowModal={setShowInviteProfileModal}
        organizationBundle={organizationBundle}
        organizationDetails={organizationDetails}
        setSentInviteData={setSentInviteData}
        setInviteSent={setInviteSent}
      />
      <ResendInviteModal
        showModal={showResendInviteModal}
        setShowModal={setShowResendInviteModal}
        onCancel={() => setShowResendInviteModal(false)}
        onConfirm={handleResendInvite}
        resendLoading={resendLoading}
      />
      <RevokeInviteModal
        showModal={showRevokeInviteModal}
        setShowModal={setShowRevokeInviteModal}
        onCancel={handleCancelRevokeInvite}
        onConfirm={handleRevokeInvites}
        revokeLoading={revokeLoading}
        selectedInviteEmails={selectedInviteEmails}
      />
      <SeatWarningModal showModal={showSeatWarningModal} setShowModal={setShowSeatWarningModal} />
    </>
  );
}
