import Modal from '@elements/Modal';
import Button from '@elements/buttons/Button';
import Spinner from 'components/admin/Spinner';

export default function ResendInviteModal({
  showModal = () => {},
  setShowModal = () => {},
  onCancel = () => {},
  onConfirm = () => {},
  resendLoading = false,
}) {
  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      modalTitle="Bevestig opnieuw verzenden"
      modalContent={
        <div>
          <p className="mb-4 mt-2 font-secondary">
            Bevestig dat je de uitnodiging opnieuw wilt versturen
          </p>
          <div className="flex justify-end gap-3 pt-2">
            {resendLoading ? (
              <Spinner spinnerSize="size-10" text="Verzoek aan het verwerken" textSize="text-md" />
            ) : (
              <>
                <Button type="button" buttonType="secondary" onClick={onCancel} label="Annuleren" />
                <Button
                  type="button"
                  buttonType="primary"
                  onClick={onConfirm}
                  label="Opnieuw versturen"
                />
              </>
            )}
          </div>
        </div>
      }
    />
  );
}
