import Icon from '@elements/Icon';
import StatusBadge from '@elements/badges/StatusBadge';
import Button from '@elements/buttons/Button';
import formatToDate from '@helpers/formatToDate';
import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper();

export const getSelectColumn = () =>
  columnHelper.display({
    id: 'select',
    enableSorting: false,
    enableGlobalFilter: false,
    header: ({ table }) => (
      <input
        type="checkbox"
        checked={table.getIsAllRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({ row }) => {
      if (row.original.isBeheerder) {
        return null;
      }
      return (
        <input
          type="checkbox"
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
        />
      );
    },
  });

export const getNameColumn = () =>
  columnHelper.accessor('naam', {
    header: ({ column }) => (
      <button
        className="flex cursor-pointer items-center gap-2"
        onClick={column.getToggleSortingHandler()}
      >
        Naam
        {column.getIsSorted() === 'asc' ? (
          <Icon iconName="arrow-up" size="xs" />
        ) : column.getIsSorted() === 'desc' ? (
          <Icon iconName="arrow-down" size="xs" />
        ) : (
          <Icon iconName="arrow-up-down" size="xs" />
        )}
      </button>
    ),
    sortingFn: 'alphanumeric',
  });

export const getEmailColumn = () =>
  columnHelper.accessor('email', {
    header: ({ column }) => (
      <button
        className="flex cursor-pointer items-center gap-2"
        onClick={column.getToggleSortingHandler()}
      >
        E-mail
        {column.getIsSorted() === 'asc' ? (
          <Icon iconName="arrow-up" size="xs" />
        ) : column.getIsSorted() === 'desc' ? (
          <Icon iconName="arrow-down" size="xs" />
        ) : (
          <Icon iconName="arrow-up-down" size="xs" />
        )}
      </button>
    ),
    sortingFn: 'alphanumeric',
  });

export const getLastLoginColumn = () =>
  columnHelper.accessor('laatsteInlogdatum', {
    header: ({ column }) => (
      <button
        className="flex cursor-pointer items-center gap-2"
        onClick={column.getToggleSortingHandler()}
      >
        Laatste inlogdatum
        {column.getIsSorted() === 'asc' ? (
          <Icon iconName="arrow-up" size="xs" />
        ) : column.getIsSorted() === 'desc' ? (
          <Icon iconName="arrow-down" size="xs" />
        ) : (
          <Icon iconName="arrow-up-down" size="xs" />
        )}
      </button>
    ),
    sortingFn: 'alphanumeric',
    cell: (info) => (info.getValue() ? formatToDate(info.getValue()) : '-'),
  });

export const getLicenseStatusColumn = () =>
  columnHelper.accessor('licentieStatus', {
    header: ({ column }) => (
      <button
        className="flex cursor-pointer items-center gap-2"
        onClick={column.getToggleSortingHandler()}
      >
        Licentie status
        {column.getIsSorted() === 'asc' ? (
          <Icon iconName="arrow-up" size="xs" />
        ) : column.getIsSorted() === 'desc' ? (
          <Icon iconName="arrow-down" size="xs" />
        ) : (
          <Icon iconName="arrow-up-down" size="xs" />
        )}
      </button>
    ),
    sortingFn: 'alphanumeric',
  });

export const getLicenseTypesColumn = () =>
  columnHelper.accessor('licenseTypes', {
    header: 'Licentie Type',
    cell: ({ row }) => {
      const licenseTypeMap = {
        'Enterprise Education': 'Educatie',
        'Enterprise Business': 'Business',
        Plus: 'Plus',
        Standard: 'Standaard',
        'Enterprise Admin': 'Admin',
      };
      const displayLicenseTypes = row.original.licenseTypes
        .map((type) => licenseTypeMap[type] || 'Standaard')
        .join(', ');
      return <span>{displayLicenseTypes}</span>;
    },
  });

export const getLinkedProfilesActionsColumn = (handleEditClick) =>
  columnHelper.display({
    id: 'actions',
    header: '',
    cell: ({ row }) => (
      <Button icon="pencil" iconSize={'sm'} onClick={() => handleEditClick(row.original)} />
    ),
  });

// Columns for InviteTable
export const getInviteSelectColumn = () =>
  columnHelper.display({
    id: 'select',
    enableSorting: false,
    enableGlobalFilter: false,
    header: ({ table }) => (
      <input
        type="checkbox"
        checked={table.getIsAllRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({ row }) => (
      <input
        type="checkbox"
        checked={row.getIsSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
  });

export const getInvitationDateColumn = () =>
  columnHelper.accessor('invitationDate', {
    header: ({ column }) => (
      <button
        className="flex cursor-pointer items-center gap-2"
        onClick={column.getToggleSortingHandler()}
      >
        Datum uitgenodigd
        {column.getIsSorted() === 'asc' ? (
          <Icon iconName="arrow-up" size="xs" />
        ) : column.getIsSorted() === 'desc' ? (
          <Icon iconName="arrow-down" size="xs" />
        ) : (
          <Icon iconName="arrow-up-down" size="xs" />
        )}
      </button>
    ),
    cell: (info) => (info.getValue() ? formatToDate(info.getValue()) : '-'),
    sortingFn: 'alphanumeric',
  });

export const getInviteStatusColumn = () =>
  columnHelper.accessor('inviteStatus', {
    header: ({ column }) => (
      <button
        className="flex cursor-pointer items-center gap-2"
        onClick={column.getToggleSortingHandler()}
      >
        Status uitnodiging
        {column.getIsSorted() === 'asc' ? (
          <Icon iconName="arrow-up" size="xs" />
        ) : column.getIsSorted() === 'desc' ? (
          <Icon iconName="arrow-down" size="xs" />
        ) : (
          <Icon iconName="arrow-up-down" size="xs" />
        )}
      </button>
    ),
    cell: (info) => <StatusBadge badgeStatus={info.getValue()} />,
  });

export const getLicenseStartDateColumn = () =>
  columnHelper.accessor('licenceStartDate', {
    header: ({ column }) => (
      <button
        className="flex cursor-pointer items-center gap-2"
        onClick={column.getToggleSortingHandler()}
      >
        Startdatum
        {column.getIsSorted() === 'asc' ? (
          <Icon iconName="arrow-up" size="xs" />
        ) : column.getIsSorted() === 'desc' ? (
          <Icon iconName="arrow-down" size="xs" />
        ) : (
          <Icon iconName="arrow-up-down" size="xs" />
        )}
      </button>
    ),
    cell: (info) => (info.getValue() ? formatToDate(info.getValue()) : '-'),
    sortingFn: 'alphanumeric',
  });

export const getLicenseEndDateColumn = () =>
  columnHelper.accessor('licenceEndDate', {
    header: ({ column }) => (
      <button
        className="flex cursor-pointer items-center gap-2"
        onClick={column.getToggleSortingHandler()}
      >
        Einddatum
        {column.getIsSorted() === 'asc' ? (
          <Icon iconName="arrow-up" size="xs" />
        ) : column.getIsSorted() === 'desc' ? (
          <Icon iconName="arrow-down" size="xs" />
        ) : (
          <Icon iconName="arrow-up-down" size="xs" />
        )}
      </button>
    ),
    cell: (info) => (info.getValue() ? formatToDate(info.getValue()) : '-'),
    sortingFn: 'alphanumeric',
  });

export const getInviteActionsColumn = (handleShowResendInviteModal) =>
  columnHelper.display({
    id: 'actions',
    header: '',
    cell: ({ row }) => (
      <Button
        icon="arrows-rotate"
        iconSize={'sm'}
        onClick={() => handleShowResendInviteModal(row.original)}
      />
    ),
  });
