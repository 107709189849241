export default function formatToDate(val) {
  if (!val) {
    return;
  }
  return new Date(val).toLocaleDateString('nl-NL', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
}
