import Icon from '@elements/Icon';
import StatusBadge from '@elements/badges/StatusBadge';
import Button from '@elements/buttons/Button';
import LinkButton from '@elements/buttons/LinkButton';
import { Popover } from '@headlessui/react';
import { classNames } from '@helpers/classNames';
import { CurrencyValue } from '@helpers/currencyFormatSummary';
import { useEnvContext } from '@helpers/envProvider';
import { formatValue } from '@helpers/formatValue';
import { getDate } from '@helpers/getDate';
import { getThumbnailUrl } from '@helpers/getThumbnailUrl';
import { translate } from '@helpers/translate';
import { getOrders } from '@providers/orders';
import { useEffect, useState } from 'react';

function OrderItem({ item, status, NEXT_PUBLIC_PROXY }) {
  const { title, documentType, publisher, document_id, price, quantity } = item;

  return (
    <section className="flex w-full p-4 md:p-8">
      {/* SMALL */}
      <div className="w-full md:hidden">
        <div className="flex justify-between">
          <div className="grid h-40 w-32 place-items-center bg-accent-3">
            <div className="aspect-[2/3] max-h-32 shadow-2xl">
              <img
                src={getThumbnailUrl(NEXT_PUBLIC_PROXY, document_id)}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/issodefault.png';
                }}
                alt="Thumbnail"
                style={{ objectFit: 'cover' }}
              />
            </div>
          </div>
          <CurrencyValue value={price} textSize="sm" />
        </div>
        <div className="flex flex-col">
          <h3 className="py-2 font-primary text-lg font-light text-primary-dark md:text-xl lg:text-2xl">
            {title}
          </h3>
          <div className="flex flex-col font-secondary text-sm font-light text-primary-dark/70">
            <div id="docType">{documentType}</div>
            <div id="publisher">{publisher}</div>
            <div id="quantity">Aantal: {quantity}</div>
            <div id="status">Status: {translate(status)}</div>
          </div>
        </div>
      </div>
      {/* MD+ */}
      <div className="hidden w-full md:flex">
        <div className="grid h-40 w-32 place-items-center bg-accent-3">
          <div className="aspect-[2/3] max-h-32 shadow-2xl">
            <img
              src={getThumbnailUrl(NEXT_PUBLIC_PROXY, document_id)}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/issodefault.png';
              }}
              alt="Thumbnail"
              style={{ objectFit: 'cover' }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4 md:flex-row">
          <div className="flex flex-col justify-between pl-4">
            <div>
              <div className="flex w-full justify-between gap-4">
                <h3 className="w-full py-2 font-primary text-xl font-light text-primary-dark md:text-2xl lg:text-2xl">
                  {title}
                </h3>
                <div className="lg:hidden">
                  <CurrencyValue value={price} />
                </div>
              </div>

              <div className="flex flex-col font-secondary text-sm text-primary-dark/70">
                <div id="docType">{documentType}</div>
                <div id="publisher">{publisher}</div>
              </div>
            </div>
            <div className="font-secondary text-sm font-light text-primary-dark/70">
              <div className="flex flex-col gap-1">
                <span>Aantal: {quantity}</span>
                <span>Status: {translate(status)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative hidden w-16 justify-end lg:flex">
        <div className="absolute top-0">
          <div className="hidden w-fit md:flex">
            <CurrencyValue value={price} />
          </div>
        </div>
      </div>
    </section>
  );
}

function OrderSummary({ orderNumber, datePlaced, totalAmount, invoiceUrl, paymentIntent }) {
  const { formattedValue } = formatValue(totalAmount);
  return (
    <>
      {paymentIntent === 'requires_payment_method' && (
        <div className="mb-0 ml-8 mt-5 overflow-hidden whitespace-nowrap">
          <StatusBadge
            badgeStatus="UNPAID"
            overrideClass="w-fit w-20 rounded-md p-2 text-center text-sm ring-inset"
          />
        </div>
      )}
      <div
        className={classNames(
          'flex items-center justify-between p-2 md:p-8',
          paymentIntent === 'requires_payment_method' && 'md:!pt-3',
        )}
      >
        <div>
          <div className="font-secondary">Ordernummer</div>
          <div className="mt-1 font-secondary text-sm text-primary-dark/70">{orderNumber}</div>
        </div>
        <div className="block">
          <div className="font-secondary">Besteldatum</div>
          <div className="mt-1 font-secondary text-sm text-primary-dark/70">
            {getDate(datePlaced)}
          </div>
        </div>
        <div>
          <div className="font-secondary">Totaal bedrag</div>
          <div className="mt-1 text-right font-secondary text-sm">{formattedValue}</div>
        </div>
        <div className="pr-2 md:pr-2 lg:hidden">
          <Popover className="relative">
            <Popover.Button>
              <Icon iconName="menu" size="xl" color="text-primary-dark" />
            </Popover.Button>
            <Popover.Panel className="absolute right-0 z-10 mt-2 w-40 origin-top-right divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-lg outline-none">
              <div className="flex flex-col justify-end gap-2 p-4">
                <Button
                  buttonType="secondary"
                  label="Bekijk details"
                  disabled
                  className="block px-4 py-2 text-left text-sm"
                  overrideClass="!max-w-full !w-full"
                />
                {invoiceUrl ? (
                  <LinkButton
                    buttonType="secondary"
                    label="Bekijk factuur"
                    href={invoiceUrl}
                    className="block !w-full px-4 py-2 text-left text-sm"
                    overrideClass="!max-w-full !w-full"
                  />
                ) : (
                  <Button
                    buttonType="secondary"
                    label="Bekijk factuur"
                    disabled
                    className="block !w-full px-4 py-2 text-left text-sm"
                    overrideClass="!max-w-full !w-full"
                  />
                )}
              </div>
            </Popover.Panel>
          </Popover>
        </div>
        <div className="hidden gap-4 lg:flex">
          <Button buttonType="secondary" label="Bekijk details" disabled />
          {invoiceUrl ? (
            <LinkButton buttonType="secondary" label="Bekijk factuur" href={invoiceUrl} />
          ) : (
            <Button buttonType="secondary" label="Bekijk factuur" disabled />
          )}
        </div>
      </div>
    </>
  );
}

function OrderCard({
  orderNumber,
  totalAmount,
  datePlaced,
  invoiceUrl,
  orderItems,
  type,
  status,
  paymentIntent,
  NEXT_PUBLIC_PROXY,
}) {
  return (
    <div className="rounded-md bg-white shadow-lg ">
      <OrderSummary
        orderNumber={orderNumber}
        totalAmount={totalAmount}
        datePlaced={datePlaced}
        invoiceUrl={invoiceUrl}
        paymentIntent={paymentIntent}
      />
      {orderItems.map((item, index) => (
        <div key={index} className="border-t">
          <OrderItem
            item={item}
            type={type}
            status={status}
            NEXT_PUBLIC_PROXY={NEXT_PUBLIC_PROXY}
          />
        </div>
      ))}
    </div>
  );
}

export default function OrdersView() {
  const [orders, setOrders] = useState([]);
  const { environment } = useEnvContext();
  const NEXT_PUBLIC_PROXY = environment['NEXT_PUBLIC_PROXY'];

  useEffect(() => {
    const fetchOrders = async () => {
      const response = await getOrders();
      if (response && response.status === 200) {
        const sortedOrders = response.data
          .sort((a, b) => new Date(b.order_date) - new Date(a.order_date))
          .filter((order) => order.payment_intent_status === 'succeeded' || order.type === 'LATER');
        setOrders(sortedOrders);
      }
    };
    fetchOrders();
  }, []);

  return (
    <div className="container mx-auto font-primary">
      <h2 className="font-primary text-3xl">Mijn bestellingen</h2>
      <p className="mb-8 mt-1 font-secondary">Bekijk hier de status van je bestellingen.</p>
      {orders.length > 0 ? (
        <div className="mb-12 flex flex-col gap-8">
          {orders.map((order, index) => (
            <OrderCard
              key={index}
              orderNumber={order.order_number}
              totalAmount={order.total_amount}
              datePlaced={order.order_date}
              invoiceUrl={order.invoice_url}
              orderItems={order.items}
              type={order.type}
              status={order.status}
              paymentIntent={order.payment_intent_status}
              NEXT_PUBLIC_PROXY={NEXT_PUBLIC_PROXY}
            />
          ))}
        </div>
      ) : (
        <div className="flex h-96 w-full items-center justify-center bg-white">
          <p className="text-center font-secondary text-lg text-gray-500">Geen bestellingen</p>
        </div>
      )}
    </div>
  );
}
