import Button from '@elements/buttons/Button';
import InputField from '@elements/forms/InputField';
import ListBox from '@elements/forms/ListBox';
import { handleValidation } from '@helpers/handleValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { getOrganizationData, updateOrganization } from '@providers/organization';
import Spinner from 'components/admin/Spinner';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { validationSchema } from './validation';

export default function OrgnanizationBusinessSettingsModalForm({
  industries,
  organizationId,
  setShowModal,
  organization,
  setOrganization,
  setIndustry,
  industry,
  setUpdated,
  setError,
}) {
  const [isValid, setIsValid] = useState({});
  const [industryValid, setIndustryValid] = useState(true);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema), mode: 'onChange' });

  function handleValidationWrapper(e, error) {
    handleValidation(e, error, trigger, setIsValid);
  }

  function handleIndustryChange(choice) {
    setIndustry(choice.value);
    customIndustryValidation(choice.value);
  }

  function customIndustryValidation(value) {
    if (value === industries.choices[0].value) {
      setIndustryValid(false);
      setIsValid({ ...isValid, industry: false });
      return false;
    } else {
      setIndustryValid(true);
      setIsValid({ ...isValid, industry: true });
      return true;
    }
  }

  async function onSubmit(formData) {
    if (!customIndustryValidation(industry)) {
      return;
    }

    if (!organization) {
      return;
    }

    const updatedOrganization = {
      organization_name: formData.organizationName,
      branch_name: industry,
      chamber_of_commerce_number: formData.chamberOfCommerceNumber,
      vat_number: formData.vatNumber,
      branch_location: formData.branchLocation,
      branch_number: formData.branchNumber,
    };

    setLoading(true);
    let response = await updateOrganization(organizationId, updatedOrganization);
    if (response && response.status === 200) {
      response = await getOrganizationData(organizationId);
      if (response && response.status === 200) {
        setOrganization(response.data);
      }
      setUpdated(true);
      setShowModal(false);
    } else {
      setError(true);
    }
    setLoading(false);
  }

  return (
    <form className="flex flex-col gap-4 pt-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-6">
        <InputField
          id="organizationName"
          label="Bedrijfsnaam*"
          name="organizationName"
          type="text"
          register={register}
          errors={errors}
          message={errors.organizationName?.message}
          onBlur={(e) => {
            handleValidationWrapper(e, errors?.organizationName);
          }}
          isValid={isValid['organizationName']}
          defaultValue={organization?.organization_name || ''}
        />
        <ListBox
          choices={industries.choices}
          label={industries.label}
          onChange={handleIndustryChange}
          errors={!industryValid}
          isValid={isValid['industry']}
          value={industry}
        />
        {!industryValid && (
          <div className="font-primary text-sm font-light tracking-wide text-functional-error">
            Branche is verplicht
          </div>
        )}
        <InputField
          id="branchLocation"
          label="Vestigingslocatie"
          name="branchLocation"
          type="text"
          register={register}
          errors={errors}
          message={errors.branchLocation?.message}
          onBlur={(e) => {
            handleValidationWrapper(e, errors?.branchLocation);
          }}
          isValid={isValid['branchLocation']}
          defaultValue={organization?.branch_location || ''}
        />
        <InputField
          id="chamberOfCommerceNumber"
          label="KvK-nummer*"
          name="chamberOfCommerceNumber"
          type="text"
          register={register}
          errors={errors}
          message={errors.chamberOfCommerceNumber?.message}
          onBlur={(e) => {
            handleValidationWrapper(e, errors?.chamberOfCommerceNumber);
          }}
          isValid={isValid['chamberOfCommerceNumber']}
          defaultValue={organization?.chamber_of_commerce_number || ''}
        />
        <InputField
          id="branchNumber"
          label="Vestigingsnummer"
          name="branchNumber"
          type="text"
          register={register}
          errors={errors}
          message={errors.branchNumber?.message}
          onBlur={(e) => {
            handleValidationWrapper(e, errors?.branchNumber);
          }}
          isValid={isValid['branchNumber']}
          defaultValue={organization?.branch_number || ''}
        />
        <InputField
          id="vatNumber"
          label="BTW-nummer*"
          name="vatNumber"
          type="tel"
          register={register}
          errors={errors}
          message={errors.vatNumber?.message}
          onBlur={(e) => {
            handleValidationWrapper(e, errors?.vatNumber);
          }}
          isValid={isValid['vatNumber']}
          defaultValue={organization?.vat_number || ''}
        />
      </div>
      <div className="flex justify-end gap-4">
        {loading ? (
          <Spinner spinnerSize="size-10" text="Verzoek aan het verwerken" textSize="text-sm" />
        ) : (
          <>
            <Button
              label="Annuleren"
              type="button"
              buttonType="secondary"
              onClick={() => setShowModal(false)}
            />
            <Button label="Versturen" type="submit" buttonType="primary" />
          </>
        )}
      </div>
    </form>
  );
}
