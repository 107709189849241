import Icon from '@elements/Icon';
import Button from '@elements/buttons/Button';
import InputField from '@elements/forms/InputField';
import { Switch } from '@headlessui/react';
import { classNames } from '@helpers/classNames';
import stripTimezoneOffset from '@helpers/stripTimezoneOffset';
import { updateLinkedProfile } from '@providers/organization';
import { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function EditLinkedProfile({
  email,
  licenceStatus,
  licenceStartDate,
  licenceEndDate,
  setShowModal,
  profileId,
  bundleId,
  organizationId,
  orgBundleExpirationDate,
  onModalClose,
  setLicenceStatus = () => {},
  setUpdatedResponse = null,
}) {
  const [startDate, setStartDate] = useState(() => {
    const start = licenceStartDate ? new Date(licenceStartDate) : new Date();
    return isNaN(start.getTime()) ? new Date() : start;
  });
  const [endDate, setEndDate] = useState(() => {
    if (!licenceEndDate) return null;
    const end = new Date(licenceEndDate);
    return isNaN(end.getTime()) ? null : end;
  });
  const [status, setStatus] = useState(licenceStatus || 'NOT_ACTIVE');
  const [expirationExceeded, setExpirationExceeded] = useState(false);

  function handleDateChange(type, e) {
    const { value } = e.target;
    if (!value) {
      if (type === 'start') setStartDate(new Date());
      if (type === 'end') setEndDate(null);
      return;
    }
    const date = new Date(value);
    if (isNaN(date.getTime())) return;

    if (type === 'start') {
      setStartDate(date);
      if (endDate && endDate < date) setEndDate(null);
    }
    if (type === 'end') {
      if (orgBundleExpirationDate && date > new Date(orgBundleExpirationDate)) {
        setExpirationExceeded(true);
      } else {
        setExpirationExceeded(false);
      }
      setEndDate(date);
    }
  }

  async function editProfileData() {
    const payload = {
      organization_id: organizationId,
      bundle_id: bundleId,
      email,
      is_active: status === 'ACTIVE',
      start_date: stripTimezoneOffset(startDate),
      expiration_date: endDate ? stripTimezoneOffset(endDate) : null,
    };

    try {
      const response = await updateLinkedProfile(profileId, payload);

      if (response && response.status === 200) {
        toast.success('Licentie toegang succesvol bewerkt', {
          position: 'top-right',
          icon: <Icon iconName="check" color={'text-green-500'} />,
          autoClose: 3000,
          bodyClassName: 'font-primary text-green-500',
        });
        if (setUpdatedResponse) setUpdatedResponse(response.data);
        setLicenceStatus(status);
        setShowModal(false);
        onModalClose();
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      toast.error('Kon Licentie toegang niet bewerken', {
        position: 'top-right',
        autoClose: 3000,
        icon: <Icon iconName="exclamation" color="text-functional-error" />,
        bodyClassName: 'font-primary text-functional-error',
      });
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <h1 className="pl-1 font-primary text-primary-dark/70">{email}</h1>
      <ActiveSwitch
        title="Licentie toegang"
        rightValue="Actief"
        leftValue="Inactief"
        status={status}
        onStatusChange={setStatus}
      />
      <InputField
        id="start_date"
        label="Selecteer begin datum*"
        name="start_date"
        type="date"
        onChange={(e) => handleDateChange('start', e)}
        onKeyDown={(e) => e.preventDefault()}
        min={new Date().toISOString().split('T')[0]}
        value={startDate.toISOString().split('T')[0]}
      />
      <InputField
        id="expiration_date"
        label="Selecteer einddatum"
        name="expiration_date"
        type="date"
        onChange={(e) => handleDateChange('end', e)}
        onKeyDown={(e) => e.preventDefault()}
        min={startDate.toISOString().split('T')[0]}
        max={
          orgBundleExpirationDate
            ? new Date(orgBundleExpirationDate).toISOString().split('T')[0]
            : undefined
        }
        value={endDate ? endDate.toISOString().split('T')[0] : ''}
        customError={expirationExceeded}
        message={expirationExceeded ? 'Licentie einddatum overschreden' : null}
      />
      <div className="flex justify-end gap-4">
        <Button
          label="Annuleren"
          type="button"
          buttonType="secondary"
          onClick={() => setShowModal(false)}
        />
        <Button
          label="Opslaan"
          type="button"
          buttonType="primary"
          onClick={editProfileData}
          disabled={expirationExceeded}
        />
      </div>
    </div>
  );
}

function ActiveSwitch({ title, rightValue, leftValue, status, onStatusChange }) {
  const [enabled, setEnabled] = useState(status === 'ACTIVE');
  return (
    <Switch.Group>
      <div className="flex justify-between">
        <h2 className="font-secondary text-sm font-bold">{title}*</h2>
        <div className="flex items-center gap-8">
          <div className={classNames('text-sm', !enabled && 'font-bold')}>{leftValue}</div>
          <Switch
            checked={enabled}
            onChange={() => {
              setEnabled(!enabled);
              onStatusChange(enabled ? 'NOT_ACTIVE' : 'ACTIVE');
            }}
            className={classNames(
              enabled ? 'bg-accent' : 'bg-accent-4',
              'relative inline-flex h-[20px] w-[44px] rounded-full transition-colors',
            )}
          >
            <span
              className={classNames(
                enabled ? 'translate-x-6' : 'translate-x-1',
                'inline-block size-[16px] translate-y-0.5 bg-white rounded-full transition-transform',
              )}
            />
          </Switch>
          <div className={classNames('text-sm', enabled && 'font-bold')}>{rightValue}</div>
        </div>
      </div>
    </Switch.Group>
  );
}
