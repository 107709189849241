import Status from '@elements/Status';

export function translate(status = '') {
  switch (status) {
    case 'Verzenden':
      return 'Shipping';
    case 'Afgeleverd':
      return 'Delivered';
    case 'Verwerkt':
      return 'Processed';
    case 'In behandeling':
      return 'Pending';
    case 'Mislukt':
      return 'Failed';
    case 'Teruggegeven':
      return 'Returned';
    case 'Geretourneerd':
      return 'Returned';
    case 'name':
      return 'Naam';
    case 'locale':
      return 'Plaats';
    case 'email':
    case 'email_address':
      return 'E-mail';
    case 'phone':
    case 'phone_number':
      return 'Telefoonnummer';
    case 'profile_validation_status':
      return 'Gevalideerd';
    case 'Ja':
      return <Status variant="ja" />;
    case 'Nee':
      return <Status variant="nee" />;
    case 'Actief':
      return <Status variant="Actief" />;
    case 'Inactief':
      return <Status variant="Inactief" />;
    case 'last_login_date':
      return 'Laatste inlogdatum';
    case 'active':
    case 'ACTIVE':
      return 'Actief';
    case 'created':
      return 'Aangemaakt';
    case 'id':
      return 'ID';
    case 'shippable':
      return 'Verzendbaar';
    case 'tax_code':
      return 'BTW-code';
    case 'unit_label':
      return 'Eenheidslabel';
    case 'updated':
      return 'Bijgewerkt';
    case 'Category':
      return 'Categorie';
    case 'DigitalProduct':
      return 'Digitaal product';
    case 'ForSale':
      return 'Te koop';
    case 'SubCategory':
      return 'Subcategorie';
    case 'ArticleNumber':
      return 'Artikel nummer';
    case 'ISBN':
      return 'ISBN';
    case 'Price':
      return 'Prijs';
    case 'start_date':
      return 'Begin datum';
    case 'end_date':
      return 'Eind datum';
    case 'expiration_date':
      return 'Vervaldatum';
    case 'license_type':
      return 'Licentie type';
    case 'license_status':
      return 'Licentiestatus';
    case 'company_name':
      return 'Bedrijfsnaam';
    case 'firstname':
      return 'Voornaam';
    case 'lastname_prefix':
      return 'Voorvoegsel';
    case 'lastname':
      return 'Achternaam';
    case 'Yes':
      return 'Ja';
    case 'No':
      return 'Nee';
    case 'invoice_url':
      return 'Factuur URL';
    case 'reference_number':
      return 'Referentienummer';
    case 'order_number':
      return 'Bestelnummer';
    case 'record_id':
      return 'Record ID';
    case 'subscription_status':
      return 'Status';
    case 'subscription_type':
      return 'Type';
    case 'city':
      return 'Stad';
    case 'country':
      return 'Land';
    case 'number':
      return 'Nummer';
    case 'number_suffix':
      return 'Toevoeging';
    case 'postcode':
      return 'Postcode';
    case 'street':
      return 'Straat';
    case 'type':
      return 'Type';
    case 'branch_name':
      return 'Vestigingsnaam';
    case 'chamber_of_commerce':
      return 'KvK';
    case 'department':
      return 'Afdeling';
    case 'vat_number':
      return 'BTW nummer';
    case 'next_invoice_date':
      return 'Volgende factuurdatum';
    case 'paid_at':
      return 'Datum betaald';
    case 'bundle_names':
      return 'Licentie type';
    case 'IN_STOCK':
    case 'In stock':
      return 'Op voorraad/POD';
    case 'OUT_OF_STOCK':
    case 'Out of stock':
      return 'Geen voorraad';
    case 'UNAVAILABLE':
    case 'Unavailable':
      return 'Onbeschikbaar';
    case 'PRE_ORDER':
    case 'Pre-order':
      return 'Vooruitbestelling';
    case 'REGELING':
      return 'Regeling';
    case 'RAPPORT':
      return 'Rapport';
    case 'HANDBOEK':
      return 'Handboek';
    case 'PRAKTIJKRICHTLIJN':
      return 'Praktijkrichtlijn';
    case 'ISSO_PUBLICATIE':
      return 'ISSO-publicatie';
    case 'ISSO_KLEINTJE':
      return 'ISSO-kleintje';
    case 'OVERIGE':
      return 'Overige';
    case 'seats':
      return 'Licentieplaatsen';
    case 'physical_direct_pay':
      return 'Direct betaald';
    case 'physical_after_pay':
      return 'Achteraf betaald';
    case 'customer_number':
      return 'Klantnummer';
    case 'amount':
      return 'Bedrag';
    case 'method':
      return 'Methode';
    case 'arrival_date':
      return 'Verwachte uitbetalingsdatum';
    case 'description':
      return 'Omschrijving';
    case 'destination':
      return 'Bestemming';
    case 'balance_transaction':
      return 'Saldotransactie';
    case 'reconciliation_status':
      return 'Afletterstatus';
    case 'payout_id':
      return 'Uitbetaling ID';
    case 'Processed':
      return 'Verwerkt';
    case 'Pending':
      return 'In behandeling';
    case 'Canceled':
      return 'Geannuleerd';
    case 'Returned':
      return 'Geretourneerd';
    case 'addresses':
      return 'Adressen';
    case 'BILLING_ADDRESS':
      return 'Factuuradres';
    case 'SHIPPING_ADDRESS':
      return 'Verzendadres';
    default:
      return '';
  }
}

export function translateForSale(enumValue = '') {
  switch (enumValue) {
    case 'IN_STOCK':
      return 'Op voorraad';
    case 'OUT_OF_STOCK':
      return 'Geen voorraad';
    case 'UNAVAILABLE':
      return 'Niet verkrijgbaar';
    case 'PRE_ORDER':
      return 'Pre-order';
    default:
      return enumValue;
  }
}

export function determineBuyButtonLabel(enumValue = '') {
  switch (enumValue) {
    case 'IN_STOCK':
      return 'In winkelwagen';
    case 'OUT_OF_STOCK':
      return 'Geen voorraad';
    case 'PRE_ORDER':
      return 'Product pre-orderen';
    default:
      return enumValue;
  }
}
